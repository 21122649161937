// src/components/Lobby.js

import React, { useState, useEffect  } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import api from '../utils/api';
// import { handleExpiredToken } from '../utils/auth';
import GameLogin from './GameLogin';  // GameLogin will be used inside the modal
import KoFiButton from './Others/KoFiButton';

function Lobby() {
  const [rooms, setRooms] = useState([]);
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isModalActive, setIsModalActive] = useState(false);

  useEffect(() => {
    // Check if the user has a nickname in cookies
    const savedNickname = Cookies.get('nickname');
    const savedUserId = Cookies.get('userId');

    if (!savedNickname || !savedUserId) {
      // If no nickname or userId is found, show the modal
      setIsModalActive(true);
    } else {
      // If the nickname is found, user is logged in
      setIsLoggedIn(true);
    }
  }, []);




  useEffect(() => {
    // handleExpiredToken();
    // Fetch available rooms from the server
    const fetchRooms = async () => {
      try {
        const response = await api.request('get', '/api/rooms')
        console.log(response);
        setRooms(response.data);
      } catch (error) {
        console.error('Error fetching rooms:', error);
      }
    };

    fetchRooms();
    // Set up an interval to fetch rooms every 10 seconds
    const interval = setInterval(fetchRooms, 5000);

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, []);

  const saveNickname = (nickname, userId) => {
    setIsLoggedIn(true);
    setIsModalActive(false); // Close modal after nickname is set
  };

  const handleCreateRoom = async () => {
    try {
      

      navigate(`/create-room`);
    } catch (error) {
      console.error('Error creating room:', error);
      alert('Failed to create room.');
    }
  };


 return  (
<div className="container">
  <div className="box">
    <h2 className="title is-4 has-text-centered">
     <img style={{marginBottom:-20, marginTop:-30}} src="/Quizzy_text_white@512px.png"></img>
      
    </h2>


     {/* Show the modal if the user is not logged in */}
     <div className={`modal ${isModalActive ? 'is-active' : ''}`}>
          <div className="modal-background"></div>
          <div className="modal-content">
            <div className="box">
              <GameLogin saveNickname={saveNickname} /> {/* GameLogin component in the modal */}
            </div>
          </div>
          <button className="modal-close is-large" aria-label="close" onClick={() => setIsModalActive(false)}></button>
        </div>


    <p className="has-text-centered">
      Join an existing room or create a new one to start playing!
    </p>
    

    <div className="buttons is-centered mt-5 mb-5">
      <button className="button is-primary" onClick={handleCreateRoom}>
        Create New Room
      </button>
    </div>

    <h3 className="title is-6 has-text-centered">Available Rooms</h3>
    
    <hr />
  
    {Object.keys(rooms).length === 0 ? (
      <p className="has-text-centered">No rooms available. Be the first to create one!</p>
    ) : (
      <ul>
        
        {Object.entries(rooms).map(([roomId, room]) => (
          <li key={roomId} className="is-flex is-justify-content-space-between mb-3">
            <span>{room.name}</span>
            {room.started && <span>Playing</span>}
            {!room.started && <span>Waiting</span>}
            <span>{Object.keys(room.players).length} players</span>
            <button
              className="button"
              onClick={() => navigate(`/room/${roomId}`)}
            >
              Join Room
            </button>
          </li>
        ))}
      </ul>
    )}
    
  </div>
  <KoFiButton />
</div>

);
}


export default Lobby;
