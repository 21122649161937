import React, { useState } from "react";

function AdvancedSettings({ roundSettings, setRoundSettings }) {
  const [selectedEffects, setSelectedEffects] = useState(roundSettings.effects || []);

  const toggleEffect = (effect) => {
    const updatedEffects = selectedEffects.includes(effect)
      ? selectedEffects.filter((e) => e !== effect)
      : [...selectedEffects, effect];

    setSelectedEffects(updatedEffects);

    // Update the round settings
    const newRoundSettings = { ...roundSettings, effects: updatedEffects };
    setRoundSettings(newRoundSettings);
  };

  return (
    <div className="box">
      <h3 className="title is-5">Advanced Image Settings</h3>

      <div className="field">
        <label className="label">Select Effects to Apply on Images</label>
        <div className="buttons has-addons">
          <button
            className={`button ${selectedEffects.includes("dark") ? "is-info" : ""}`}
            onClick={() => toggleEffect("dark")}
          >
            Dark
          </button>
          <button
            className={`button ${selectedEffects.includes("dezoom") ? "is-info" : ""}`}
            onClick={() => toggleEffect("dezoom")}
          >
            Dezoom
          </button>
          <button
            className={`button ${selectedEffects.includes("blur") ? "is-info" : ""}`}
            onClick={() => toggleEffect("blur")}
          >
            Blur
          </button>
        </div>
      </div>
    </div>
  );
}

export default AdvancedSettings;
