import React, { useState, useEffect } from 'react';
import api from '../../utils/api';

function ManageThemes({ onThemeAdded }) {
  const [themes, setThemes] = useState([]);
  const [themeName, setThemeName] = useState('');

  const fetchThemes = async () => {
    try {
      const response = await api.request("get", "/api/themes");
      setThemes(response.data);
    } catch (error) {
      console.error('Error fetching themes:', error);
    }
  };

  useEffect(() => {
    fetchThemes();
  }, []);

  const handleAddTheme = async () => {
    try {
      console.log("themeName", themeName);
      const response = await api.request("post", "/api/themes", { name: themeName });
      if (response.status === 201) {
        setThemes([...themes, response.data]);
        setThemeName('');
      } else {
        alert('Failed to add theme');
      }

      if (onThemeAdded) onThemeAdded();
    } catch (error) {
      console.error('Error adding theme:', error);
    }
  };

  const handleDeleteTheme = async (id) => {
    try {
      await api.request("delete", `/api/themes/${id}`);
      setThemes(themes.filter((theme) => theme._id !== id));
      if (onThemeAdded) onThemeAdded();
    } catch (error) {
      console.error('Error deleting theme:', error);
    }
  };

  return (
    <div>
      <h2 className="title">Manage Themes</h2>
      <div className="field is-grouped">
        <div className="control is-expanded">
          <input
            className="input"
            type="text"
            placeholder="Theme Name"
            value={themeName}
            onChange={(e) => setThemeName(e.target.value)}
          />
        </div>
        <div className="control">
          <button className="button is-primary" onClick={handleAddTheme}>
            Add Theme
          </button>
        </div>
      </div>

      <ul className="list">
        {themes.map((theme) => (
          <li key={theme._id} className="box is-flex is-justify-content-space-between is-align-items-center">
            <span>{theme.name}</span>
            <button className="button is-small is-danger" onClick={() => handleDeleteTheme(theme._id)}>
              Delete
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default ManageThemes;
