import React from "react";
import Navbar from "../Navbar";
import Leaderboard from "./Leaderboard";
import Timer from "./Timer";
import QuestionDisplay from "./QuestionDisplay";
import JokerControls from "./JokerControls";
import useGameLogic from "./useGameLogic";
import "./GameRoom.css";
import { useNavigate } from 'react-router-dom';

function GameRoom() {
  const {
    roomName,
    score,
    currentQuestion,
    timeLeft,
    progress,
    gameStarted,
    gameOver,
    finalScores,
    isCreator,
    handleStartGame,
    handleAnswer,
    getButtonClass,
    jokerCounts,
    handleUseJoker,
    playerAnswer,
    correctAnswer,
    playerAnswers,
    nbQuestions,
    questionIndex,
  } = useGameLogic();

  const navigate = useNavigate();

  if (gameOver) {
    return (
      <div>
        <div className="container is-max-tablet">
          <div className="block m-1 has-text-centered">
          <h2 className="title is-4">Game Over</h2>
          <Leaderboard finalScores={finalScores} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="container">
        <div className="columns">
          {/* Main Game Content */}
          <div className="column is-two-thirds">
            <div className="block m-1 game-content">
            <div className="is-flex is-justify-content-space-between">
  <h2 className="title is-4">Game Room: {roomName}</h2>
  <h3 className="subtitle is-4">{questionIndex}/{nbQuestions}</h3>
</div>
              

              <QuestionDisplay
                currentQuestion={currentQuestion}
                handleAnswer={handleAnswer}
                getButtonClass={getButtonClass}
                hasAnswered={playerAnswer !== null}
                correctAnswer={correctAnswer}
                playerAnswers={playerAnswers}
                timeLeft={timeLeft}
                progress={progress}
              />

              {/* <JokerControls jokerCounts={jokerCounts} handleUseJoker={handleUseJoker} /> */}

              {!gameStarted && (
                <div className="container has-text-centered">
                  <h2 className="title is-4">Waiting to start the game...</h2>
                  {isCreator && (
                    <button
                      className="button is-primary"
                      onClick={handleStartGame}
                    >
                      Start Game
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>

          {/* Leaderboard */}
          <div className="column is-one-third m-1">
            <Leaderboard
              finalScores={finalScores}
              playerAnswers={playerAnswers}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default GameRoom;
