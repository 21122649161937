import React, { useState } from "react";
import api from "../../utils/api";
import QuestionList from "./QuestionList";  // Assuming you already have a QuestionList component

function UploadQuestions() {
  const [file, setFile] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [message, setMessage] = useState("");
  const [confirmed, setConfirmed] = useState(false); // Track confirmation

  // Handle file change and parse JSON
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFile(file);
    setConfirmed(false); // Reset the confirmation

    const reader = new FileReader();
    reader.onload = (event) => {
      try {
        const json = JSON.parse(event.target.result);
        setQuestions(json); // Set the questions in state for preview
        setMessage("");  // Clear any previous message
      } catch (error) {
        setMessage("Error parsing JSON file. Please upload a valid JSON file.");
      }
    };

    if (file) {
      reader.readAsText(file);
    }
  };

  // Handle confirmation (user accepts to upload questions)
  const handleConfirm = () => {
    setConfirmed(true);
  };

  // Handle file upload (triggered only after confirmation)
  const handleUpload = async () => {
    if (!file) {
      setMessage("Please select a file first!");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await api.request("post", "/api/questions/upload-json", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (response.data.success) {
        setMessage("Questions uploaded successfully!");
        setFile(null);
        setQuestions([]);
      } else {
        setMessage("Error uploading questions.");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      setMessage("An error occurred while uploading the file.");
    }
  };

  return (
    <div className="container">
      <h2 className="title is-4 has-text-centered">Upload Questions JSON</h2>
      <div className="box">
        <div className="field">
          <label className="label">Upload Questions JSON</label>
          <input type="file" className="input" onChange={handleFileChange} accept=".json" />
        </div>

        {/* Display questions for preview */}
        {questions.length > 0 && (
          <div>
            <h3 className="title is-5">Questions to be Uploaded:</h3>
            <QuestionList questions={questions} />

            {!confirmed && (
              <div className="buttons is-centered mt-4">
                <button className="button is-primary" onClick={handleConfirm}>
                  Confirm Upload
                </button>
              </div>
            )}
          </div>
        )}

        {/* Upload button (shown only after confirmation) */}
        {confirmed && (
          <div className="buttons is-centered mt-4">
            <button className="button is-success" onClick={handleUpload}>
              Upload Questions
            </button>
          </div>
        )}

        {message && <div className="notification is-info mt-4">{message}</div>}
      </div>
    </div>
  );
}

export default UploadQuestions;
