import React, { useState, useEffect } from 'react';
import api from '../../utils/api';
import QuestionForm from './QuestionForm';
import QuestionList from './QuestionList';

function ManageQuestions() {
  const [questions, setQuestions] = useState([]);
  const [themes, setThemes] = useState([]);
  const [editingQuestion, setEditingQuestion] = useState(null);

  const fetchQuestions = async () => {
    try {
      const response = await api.request("get", "/api/questions");
      setQuestions(response.data);
    } catch (error) {
      console.error('Error fetching questions:', error);
    }
  };

  const fetchThemes = async () => {
    try {
      const response = await api.request("get", "/api/themes");
      setThemes(response.data);
    } catch (error) {
      console.error('Error fetching themes:', error);
    }
  };

  useEffect(() => {
    fetchQuestions();
    fetchThemes();
  }, []);

  const handleEditQuestion = (question) => {
    setEditingQuestion(question);
  };

  const handleDeleteQuestion = async (id) => {
    try {
      await api.request("delete", `/api/questions/${id}`);
      fetchQuestions();
    } catch (error) {
      console.error('Error deleting question:', error);
    }
  };

  return (
    <div className="container">
      <QuestionForm
        onQuestionSaved={fetchQuestions}
        editingQuestion={editingQuestion}
        themes={themes}
      />
      <QuestionList
        questions={questions}
        onEditQuestion={handleEditQuestion}
        onDeleteQuestion={handleDeleteQuestion}
        themes={themes}
      />
    </div>
  );
}

export default ManageQuestions;
