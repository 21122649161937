import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';

function GameLogin({ saveNickname }) {
  const [nickname, setNickname] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

//   useEffect(() => {
//     // Check if the user has a nickname and ID in cookies
//     const savedNickname = Cookies.get('nickname');
//     const savedUserId = Cookies.get('userId');

//   }, []);

  const save = () => {
    if (!nickname.trim()) {
      setErrorMessage('Please enter a valid nickname');
      return;
    }

    // Generate a unique ID for the user if not already set
    const userId = Cookies.get('userId') || generateUniqueId();
    Cookies.set('nickname', nickname, { expires: 7 });  // Store the nickname in a cookie for 7 days
    Cookies.set('userId', userId, { expires: 7 });      // Store the unique ID in a cookie for 7 days
    saveNickname(nickname, userId);


  };

  // Generate a random unique ID (you can replace this with a better ID generation method)
  const generateUniqueId = () => {
    return 'id-' + Math.random().toString(36).substr(2, 9);
  };


  return (
    <div className="container">
      <div className="box">
        <h2 className="title is-4 has-text-centered">Enter Your Nickname</h2>
        <div className="field">
          <label className="label">Nickname</label>
          <div className="control">
            <input
              className="input"
              type="text"
              placeholder="Enter your nickname"
              value={nickname}
              onChange={(e) => setNickname(e.target.value)}
            />
          </div>
          {errorMessage && <p className="help is-danger">{errorMessage}</p>}
        </div>

        <div className="buttons is-centered">
          <button className="button is-primary" onClick={save}>
            Join Game
          </button>
        </div>
      </div>
    </div>
  );
}

export default GameLogin;
