import React, { useState, useEffect, useRef } from "react";

const QuestionInput = ({ currentQuestion, handleAnswer, hasAnswered }) => {
  const [typedAnswer, setTypedAnswer] = useState("");
  const inputRef = useRef(null); // Create a reference for the input field
  useEffect(() => {
    
    if (currentQuestion) {
      setTypedAnswer(""); // Clear the input field when a new question is displayed
    }

  }, [currentQuestion]);
  useEffect(() => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (inputRef.current && !hasAnswered && !isMobile) {
      
      inputRef.current.focus(); // Set autofocus on the input field when the component loads
    }
  }, [hasAnswered]);
  const handleSubmitAnswer = () => {
    if (typedAnswer.trim() !== "") {
      handleAnswer(typedAnswer.trim());
      // setTypedAnswer(""); // Clear the input field after submission
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent default form submission
      handleSubmitAnswer(); // Submit the answer when Enter is pressed
    }
  };

  return (
    <div className="field is-grouped is-justify-content-center">
      <div className="control">
        <input
          type="text"
          className="input"
          value={typedAnswer}
          ref={inputRef}
          onChange={(e) => setTypedAnswer(e.target.value)}
          placeholder="Type your answer here"
          disabled={hasAnswered}
          onKeyDown={handleKeyDown} // Trigger handleSubmitAnswer on Enter key press
        />
      </div>
      <div className="control">
        <button
          className="button is-link"
          onClick={handleSubmitAnswer}
          disabled={hasAnswered || typedAnswer.trim() === ""}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default QuestionInput;
