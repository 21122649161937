import React from "react";

const Timer = ({ timeLeft, progress }) => {
  const getProgressBarColor = () => {
    if (progress > 50) return "is-primary";
    if (progress > 20) return "is-warning";
    return "is-danger";
  };

  return (
    <div className="field">
      {/* <label className="label has-text-centered">Time Left: {timeLeft} seconds</label> */}
      <progress className={`progress is-small ${getProgressBarColor()}`} value={progress} max="100">
        {progress}%
      </progress>
    </div>
  );
};

export default Timer;
