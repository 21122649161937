// src/components/App.js

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Lobby from './Lobby';
import GameRoom from './GameRoom/GameRoom';
import Login from './Login';
import Register from './Register';
import LanguageSwitcher from './LanguageSwitcher';
import CreateRoom from './CreateRoom/CreateRoom';
import ManageQuestions from './ManageQuestions/ManageQuestions'; // Import the component
import 'bulma/css/bulma.min.css';
import Navbar from './Navbar.js';
import UploadQuestions from './ManageQuestions/UploadQuestions';


function App() {
  return (

      <Router>
        {/* <LanguageSwitcher /> */}
        <Navbar />
        <Routes>
          <Route path="/room/:id" element={<GameRoom />} />
          <Route path="/create-room" element={<CreateRoom />} />
          <Route path="/manage-questions" element={<ManageQuestions />} />
          <Route path="/upload-questions" element={<UploadQuestions />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/" element={<Lobby />} />
        </Routes>
      </Router>

  );
}

export default App;
