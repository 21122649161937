import React from 'react';

const KoFiButton = () => {
  return (
    <div className="ko-fi-button" style={{ textAlign: 'center', margin: '20px 0' }}>
      <a href="https://ko-fi.com/H2H814IRDE" target="_blank" rel="noopener noreferrer">
        <img
          height="36"
          style={{ border: '0px', height: '36px' }}
          src="https://storage.ko-fi.com/cdn/kofi4.png?v=3"
          alt="Buy Me a Coffee at ko-fi.com"
        />
      </a>
    </div>
  );
};

export default KoFiButton;
