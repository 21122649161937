// src/components/CreateRoom.js

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../utils/api";

import Cookies from "js-cookie";
import AdvancedSettings from "./AdvancedSettings";
function CreateRoom() {
  const [roomName, setRoomName] = useState("");
  // const [language, setLanguage] = useState("en");
  const [themes, setThemes] = useState([]);
  // const [difficulty, setDifficulty] = useState("easy");
  // const [numberOfQuestions, setNumberOfQuestions] = useState(10);
  // const [selectedThemes, setSelectedThemes] = useState([]);
  const [activeTab, setActiveTab] = useState("basic"); // Track which tab is active
  const [rounds, setRounds] = useState([
    {
      roundType: "speed-based",
      questionType: "input-based", // Add this field
      themes: [],
      timeLimit: 15,
      numberOfQuestions: 10,
      scoring: "standard",
      difficulties: ["easy", "medium", "hard"], // Add this field
    },
  ]);
  const [availableDifficulties, setAvailableDifficulties] = useState([
    "easy",
    "medium",
    "hard",
  ]);
  const navigate = useNavigate();

  useEffect(() => {
    // Check if nickname and userId are in cookies
    const savedNickname = Cookies.get("nickname");
    const savedUserId = Cookies.get("userId");

    if (!savedNickname || !savedUserId) {
      // If no nickname or userId is found, redirect to the login page or lobby
      navigate("/"); // Redirect to the lobby or login page to get the nickname
    }
  }, [navigate]);

  const handleAddRound = () => {
    setRounds([
      ...rounds,
      {
        roundType: "standard",
        themes: [],
        timeLimit: 30,
        numberOfQuestions: 5,
        scoring: "standard",
      },
    ]);
  };

  useEffect(() => {
    const fetchThemes = async () => {
      try {
        const response = await api.request("get", "/api/themes");
        setThemes(response.data);
      } catch (error) {
        console.error("Error fetching themes:", error);
      }
    };

    fetchThemes();
  }, []);

  const handleCreateRoom = async (event) => {
    event.preventDefault();

    try {

      // const settings = { language, themes: selectedThemes, difficulty, numberOfQuestions };
      const response = await api.request("post", "/api/rooms/create", {
        roomName,
        rounds,
        userId: Cookies.get("userId"),
      });

      const gameId = response.data;
      navigate(`/room/${gameId}`);
    } catch (error) {
      console.error("Error creating room:", error);
    }
  };

  const updateRoundSettings = (index, newSettings) => {
    const newRounds = [...rounds];
    newRounds[index] = { ...newRounds[index], ...newSettings };
    setRounds(newRounds);
  };

  // const handleThemeToggle = (theme) => {
  //   setSelectedThemes((prevThemes) =>
  //     prevThemes.includes(theme)
  //       ? prevThemes.filter((t) => t !== theme)
  //       : [...prevThemes, theme]
  //   );
  // };

  return (
    <div>
      <div className="container">
        <div className="columns is-centered">
          <div className="column is-5">
            {" "}
            {/* Narrower column */}
            <div className="box">
              <h2 className="title is-4 has-text-centered">Create Game Room</h2>

              <div className="field">
                <label className="label">Room Name</label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    value={roomName}
                    onChange={(e) => setRoomName(e.target.value)}
                    placeholder="Enter Room Name"
                  />
                </div>
              </div>

              {/* Tabs to switch between Basic and Advanced settings */}
              <div className="tabs is-centered">
                <ul>
                  <li className={activeTab === "basic" ? "is-active" : ""}>
                    <a onClick={() => setActiveTab("basic")}>Basic Settings</a>
                  </li>
                  <li className={activeTab === "advanced" ? "is-active" : ""}>
                    <a onClick={() => setActiveTab("advanced")}>
                      Advanced Settings
                    </a>
                  </li>
                </ul>
              </div>

              {/* Basic Settings */}
              {activeTab === "basic" && (
                <>
                  {rounds.map((round, index) => (
                    <div className="box" key={index}>
                      <div className="field">
                        <label className="label">Round Type</label>
                        <div className="control">
                          <div className="buttons has-addons is-centered">
                            <button
                              className={`button ${
                                round.roundType === "standard"
                                  ? "is-info is-selected"
                                  : ""
                              }`}
                              onClick={() => {
                                const newRounds = [...rounds];
                                newRounds[index].roundType = "standard";
                                setRounds(newRounds);
                              }}
                            >
                              Standard
                            </button>
                            <button
                              className={`button ${
                                round.roundType === "speed-based"
                                  ? "is-info is-selected"
                                  : ""
                              }`}
                              onClick={() => {
                                const newRounds = [...rounds];
                                newRounds[index].roundType = "speed-based";
                                setRounds(newRounds);
                              }}
                            >
                              Speed-Based
                            </button>
                            <button
                              className={`button ${
                                round.roundType === "buzzer"
                                  ? "is-info is-selected"
                                  : ""
                              }`}
                              onClick={() => {
                                const newRounds = [...rounds];
                                newRounds[index].roundType = "buzzer";
                                setRounds(newRounds);
                              }}
                            >
                              Buzzer
                            </button>
                          </div>
                        </div>
                      </div>

                      {/* Difficulty Settings */}
                      {/* Difficulty Settings - Multiple Selection */}
                      <div className="field">
                        <label className="label">Difficulty</label>
                        <div className="control">
                          <div className="buttons has-addons is-centered">
                            <button
                              className={`button ${
                                round.difficulties &&
                                round.difficulties.includes("easy")
                                  ? "is-success is-selected"
                                  : ""
                              }`}
                              onClick={() => {
                                const newRounds = [...rounds];
                                if (
                                  newRounds[index].difficulties &&
                                  newRounds[index].difficulties.includes("easy")
                                ) {
                                  // Remove "easy" if it's already selected
                                  newRounds[index].difficulties = newRounds[
                                    index
                                  ].difficulties.filter(
                                    (difficulty) => difficulty !== "easy"
                                  );
                                } else {
                                  // Add "easy" to the difficulties list
                                  newRounds[index].difficulties = [
                                    ...(newRounds[index].difficulties || []),
                                    "easy",
                                  ];
                                }
                                setRounds(newRounds);
                              }}
                            >
                              Easy
                            </button>
                            <button
                              className={`button ${
                                round.difficulties &&
                                round.difficulties.includes("medium")
                                  ? "is-warning is-selected"
                                  : ""
                              }`}
                              onClick={() => {
                                const newRounds = [...rounds];
                                if (
                                  newRounds[index].difficulties &&
                                  newRounds[index].difficulties.includes(
                                    "medium"
                                  )
                                ) {
                                  // Remove "medium" if it's already selected
                                  newRounds[index].difficulties = newRounds[
                                    index
                                  ].difficulties.filter(
                                    (difficulty) => difficulty !== "medium"
                                  );
                                } else {
                                  // Add "medium" to the difficulties list
                                  newRounds[index].difficulties = [
                                    ...(newRounds[index].difficulties || []),
                                    "medium",
                                  ];
                                }
                                setRounds(newRounds);
                              }}
                            >
                              Medium
                            </button>
                            <button
                              className={`button ${
                                round.difficulties &&
                                round.difficulties.includes("hard")
                                  ? "is-danger is-selected"
                                  : ""
                              }`}
                              onClick={() => {
                                const newRounds = [...rounds];
                                if (
                                  newRounds[index].difficulties &&
                                  newRounds[index].difficulties.includes("hard")
                                ) {
                                  // Remove "hard" if it's already selected
                                  newRounds[index].difficulties = newRounds[
                                    index
                                  ].difficulties.filter(
                                    (difficulty) => difficulty !== "hard"
                                  );
                                } else {
                                  // Add "hard" to the difficulties list
                                  newRounds[index].difficulties = [
                                    ...(newRounds[index].difficulties || []),
                                    "hard",
                                  ];
                                }
                                setRounds(newRounds);
                              }}
                            >
                              Hard
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="field">
                        <label className="label">Question Type</label>
                        <div className="control">
                          <div className="buttons has-addons is-centered">
                            <button
                              className={`button ${
                                round.questionType === "multiple-choice"
                                  ? "is-info is-selected"
                                  : ""
                              }`}
                              onClick={() => {
                                const newRounds = [...rounds];
                                newRounds[index].questionType =
                                  "multiple-choice";
                                setRounds(newRounds);
                              }}
                            >
                              Multiple Choice
                            </button>
                            <button
                              className={`button ${
                                round.questionType === "input-based"
                                  ? "is-info is-selected"
                                  : ""
                              }`}
                              onClick={() => {
                                const newRounds = [...rounds];
                                newRounds[index].questionType = "input-based";
                                setRounds(newRounds);
                              }}
                            >
                              Input Based
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="field">
                        <label className="label">Number of Questions</label>
                        <div className="control">
                          <input
                            className="input"
                            type="number"
                            value={round.numberOfQuestions}
                            onChange={(e) => {
                              const newRounds = [...rounds];
                              newRounds[index].numberOfQuestions = parseInt(
                                e.target.value,
                                10
                              );
                              setRounds(newRounds);
                            }}
                            placeholder="Enter Number of Questions"
                          />
                        </div>
                      </div>

                      <div className="field">
                        <label className="label">Select Themes</label>
                        <div className="control">
                          <div className="columns is-multiline">
                            {" "}
                            {/* Wrap checkboxes in Bulma columns */}
                            {themes.map((theme) => (
                              <div
                                className="column is-one-third"
                                key={theme._id}
                              >
                                {" "}
                                {/* Organize into columns */}
                                <label className="checkbox">
                                  <input
                                    type="checkbox"
                                    value={theme.name}
                                    checked={round.themes.includes(theme.name)}
                                    onChange={(e) => {
                                      const selectedThemes =
                                        round.themes.includes(e.target.value)
                                          ? round.themes.filter(
                                              (theme) =>
                                                theme !== e.target.value
                                            )
                                          : [...round.themes, e.target.value];

                                      const newRounds = [...rounds];
                                      newRounds[index].themes = selectedThemes;
                                      setRounds(newRounds);
                                    }}
                                  />
                                  <span className="ml-2">{theme.name}</span>{" "}
                                  {/* Add margin for spacing */}
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              )}

              {activeTab === "advanced" && (
                <>
                  {rounds.map((round, index) => (
                    <AdvancedSettings
                      key={index}
                      roundSettings={round}
                      setRoundSettings={(newSettings) =>
                        updateRoundSettings(index, newSettings)
                      }
                    />
                  ))}
                </>
              )}

              <div className="buttons is-centered">
                <button className="button is-info" onClick={handleAddRound}>
                  Add Round
                </button>
                <button
                  className="button is-primary"
                  onClick={handleCreateRoom}
                >
                  Create Room
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateRoom;
